.MP3 {
  text-align: center;
  display: flex;

}
.card {
    border: none;
}
.card.name {
    max-width: 4rem;
}
.card.player {
    max-width: 20rem;
}
.card-deck {
border: 1px;
border-color: #cccccc;
border-style: solid;
}
.card.comment-item {
    border-style: solid solid solid solid;
    border-color: #f7f7f7;
    border-width: 5px;
    background-color: #f7f7f7;
    margin-right: 0;
}
.card.ud-ellipsis {
    max-width: 2rem;
    background-color: #f7f7f7;
    margin-left: 0;

}
.ud-button {
    border-style: none none none none ;
    border-width: 2px;
    border-color: #999999;

    background-color: #f7f7f7;
}
.ud-button:hover {
    /* found this to be a decent hover, same as focus'd */
    outline: 5px auto -webkit-focus-ring-color;
}
.card-deck.comment-items {
    border-style: none none none none;
    border-width: 1px;
    border-color: #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
}
.card-deck.add-comment {
    border-style: none none none none;
    border-width: 1px;
    border-color: #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
}
.card.add-comment {
    border-style: solid solid solid solid;
    border-color: #f7f7f7;
    border-width: 5px;
    background-color: #f7f7f7;
}

.card-deck.mp3 {
    border-style: solid solid solid solid;
    border-width: 1px;
    border-color: #f0f0f0;
    padding-bottom: 10px;
    padding-top: 10px;
}


.like {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #ffffff;

}

.comment {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #ffffff;

}
.like:hover {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #f0f0f0;

}

.comment:hover {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #f0f0f0;

}
.like:active {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #aaaaaa;

}

.comment:active {
        border-style: solid solid solid solid;
        border-width: 2px;
        border-color: #aaaaaa;

}


figure.likes {
          margin: 20px;
          max-height: 3rem;
          margin: 0;
}
figcaption.likes {
            max-width: 5rem;
            max-height: 2rem;
            display: inline-block;
            vertical-align: middle;
            margin-left: -35px;
            margin-top: -10px
}
img.like {
    max-width: 10rem;
}
img.comment {
    max-width: 8rem;
}

img.comments {
    text-align: left;
}
img.likes {
            max-width: 6rem;
            max-height: 3rem;
            display: inline-block;
            vertical-align: middle
}

.form-control.addcomment {
        border-color: #81bdff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 255, 0.5);
 }
